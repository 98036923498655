import React, { useState, useEffect, useRef } from 'react';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { useSession, signOut } from 'next-auth/react';

import Tabs from 'comps/tabs';
import Logo from 'comps/icons/saLogo';

import Notifications from 'libs/notifications';
import Authentication from "../../models/authentication";
import ForgotPassword from 'views/auth/forgot-password';
import Tippy from "@tippyjs/react";

export const Translators = () => {
	const _baseUrl = '/api/ext/users';

	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [codeFieldVisible, setCodeFieldVisible] = useState(false);
	const [loginButtonVisible, setLoginButtonVisible] = useState(false);
	const [showEmailButtons, setShowEmailsButtons] = useState(true);
	const [codeInput, setCodeInput] = useState('');
	const [message, setMessage] = useState('');
	const [message2, setMessage2] = useState('');
	const { data: session } = useSession();
	const router = useRouter();

	if (session?.user?.role === 'Translator') {
		router.push('/dashboard');
	}

	const emailInputRef = useRef(null);

	const onTextInputKeyDown = (e) => {
		if (e.keyCode == 13) {
			e.preventDefault();

			onEmailSendCode(e);
			return;
		}
	};

	const onDigitCodeKeyDown = (e) => {
		if (e.keyCode == 13) {
			e.preventDefault();

			signInWithCode(e);
			return;
		}
	};

	const signInWithCode = async (e) => {
		e?.preventDefault();

		try {
			signIn('translator', {
				email: email,
				code: codeInput
			});
		} catch (error) {
			Notifications.error(error)
		}
	}

	const onEmailSendCode = async (e) => {
		e && e?.preventDefault();

		if (email === '') {
			return;
		}

		setIsLoading(true);

		try {
			let res = await fetch('/api/ext/users/email-code', {
				method: 'POST',
				body: JSON.stringify({
					email: email,
				}),
				headers: {
					'Content-Type': 'application/json'
				},
			});

			res = await res.json();

			if (res.error) {
				throw res;
			}

			setIsLoading(false);

			if (res) {
				setCodeFieldVisible(true);
				setLoginButtonVisible(true);
				setShowEmailsButtons(false);
				setMessage(res.message);
				setMessage2(res.message2);
				Notifications.success('One-Time Password Sent. Check your email.');
				return;
			}

			Notifications.error(res?.msg);
		} catch (err) {
			setIsLoading(false);
			Notifications.error(err.error);
			console.error(err);
		}
	};

	return (
		<>
			<div className="translatorsForm">
				{isLoading && (
					<div
						title="Loading"
						className="spinner-border spinner-border-sm mt-2"
						role="status"
					>
						<span className="sr-only">Loading...</span>
					</div>
				)}
				{message !== '' ? (
					<div>{message} <br/>{message2}</div>
				) : ''}
				{codeFieldVisible === false ? (
					<div>
						<input
							type="email"
							className="form-control mt-4"
							placeholder="Enter email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							style={{minWidth: '400px'}}
							onKeyDown={(e) => onTextInputKeyDown(e)}
							ref={emailInputRef}
							required
						/>
					</div>
				) : ''}
				{codeFieldVisible === true ? (
					<div>
						{/*<label htmlFor="email-code">Code</label>*/}
						<input
							type="text"
							className="form-control mt-2"
							placeholder="Enter 6-digits"
							value={codeInput}
							onChange={(e) => setCodeInput(e.target.value)}
							onKeyDown={(e) => onDigitCodeKeyDown(e)}
							style={{minWidth: '400px'}}
							id="email-code"
						/>
						<Tippy

							content={<span>Didn't receive the email? Click 'Resend OTP' for a new code.</span>}
							placement="right"
							theme="sa"
						>
							<div style={{
								color: '#babbbc',
								cursor: 'pointer',
								float: 'right'
							}} onClick={() => onEmailSendCode(null)}>
								Resend a One-Time Password
							</div>
						</Tippy>
						<br/>
					</div>
				) : ''}
				{showEmailButtons === true ? (
					<div className="buttons-emails">
						<div>
							<button
								className="btn btn-primary send-code-btn mt-4"
								onClick={onEmailSendCode}
								disabled={isLoading}
							>
								Next
							</button>
						</div>
					</div>
				) : ''}

				{loginButtonVisible ? (
					<button
						className="btn btn-primary send-code-btn mt-4"
						onClick={signInWithCode}
						disabled={isLoading}
					>
						Sign In
					</button>
				) : ''}
			</div>
			<style jsx>{`
				.translatorsForm {
					padding-top: 1rem;
					display: flex;
					flex-flow: column;
					align-items: center;
				}

				.buttons-emails > div {
					margin: 2px;
				}

				.buttons-emails {
					display: flex;
					flex-direction: row;
				}
				.send-code-btn {
					color: #fff;
				}
			`}</style>
		</>
	);
};

const ProjectManagers = () => {
	const [forgotPasswordForm, setForgotPasswordForm] = useState(false);
	const router = useRouter();

	const submit = (e) => {
		e && e.preventDefault();

		const pData = {
			email: e.target.login.value,
			pass: e.target.pass.value,
			callbackUrl:
				router?.query?.callbackUrl ?? process.env.NEXT_PUBLIC_FRONTEND_URL,
		};

		signIn('credentials', pData);
	};

	const err = router?.query?.error ?? null;

	if (forgotPasswordForm === true) {
		return (
			<ForgotPassword signIn={() => setForgotPasswordForm(!forgotPasswordForm)}/>
		)
	}

	return (
		<>
			<form onSubmit={submit} className="frmLogin">
				<div className="form-group">
					<input
						type="text"
						name="login"
						className="form-control authInput"
						required
						defaultValue=""
						autoComplete="username"
						placeholder="Username"
					/>
				</div>
				<div>
					<input
						type="password"
						name="pass"
						className="form-control authInput"
						required
						defaultValue=""
						autoComplete="current-password"
						placeholder="Password"
					/>

					<span style={{
						color: '#babbbc',
						cursor: 'pointer',
						float: 'right'
					}} onClick={() => setForgotPasswordForm(!forgotPasswordForm)}>Forgot password?</span>
					<br/>
				</div>
				<div className="form-group text-center">
					<button type="submit" className="btn btn-primary">
						SIGN IN
					</button>
				</div>
			</form>
			{err && (
				<div className="form-group text-center">
					<em>
						Something went wrong.
						<br />
						Please try again
					</em>
				</div>
			)}
			<style jsx global>
				{`
					@import 'scss/commons';

					.authInput {
						min-width: 400px;
					}
				`}
			</style>
		</>
	);
};

const SignIn = () => {
	return (
		<div className="viewLogin">
			<div className="leftSide"></div>
			<div className="rightSide">
				<div className="logoCnt">
					<Logo />
				</div>

				<Tabs>
					<TabList>
						<Tab>
							<div className="pl-4 pr-4">For Translators</div>
						</Tab>
						<Tab>
							<div className="pl-4 pr-4">For Project Managers</div>
						</Tab>
					</TabList>

					<TabPanel className="translators">
						<Translators />
					</TabPanel>
					<TabPanel className="projectManagers">
						<ProjectManagers />
					</TabPanel>
				</Tabs>
			</div>

			<style jsx global>
				{`
					@import 'scss/commons';
				`}
			</style>
			<style jsx>{`
				@import 'scss/base';

				.viewLogin {
					display: flex;
					flex-flow: row nowrap;
					min-height: 100vh;

					.leftSide {
						flex: 1;
						background: $body-bg url('/img/loginBG.png') center/contain
							no-repeat;
						background-blend-mode: multiply;
						display: none;

						@include media-breakpoint-up(md) {
							display: block;
						}
					}

					:global(.rightSide) {
						flex: 1;
						display: flex;
						flex-flow: column nowrap;
						align-items: center;
						justify-content: center;
						box-shadow: 0 0 15px 15px rgba(0, 0, 0, 0.1);
						background: #fff;

						:global(.projectManagers, .translators) {
							justify-content: center;
							padding: 1rem;
						}
					}

					.logoCnt {
						width: 25%;
						margin: $spacer * 4 auto $spacer * 2;
					}

					.frmLogin {
						width: 60%;
					}
				}
			`}</style>
		</div>
	);
};

export default SignIn;
